import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

export default () => (
  <Layout>
    <SEO title="404" />
    <h1>QUATSCH</h1>
    <p>Was machst du da?</p>
  </Layout>
)
